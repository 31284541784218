<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      v-if="showBar"
      height="45"
      dark
    >
      <div class="d-flex align-center">
        <h1>DM0MAX - Signal</h1>
        <p class="font-weight-thin">ALPHA</p>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center ">
        <h1 class="title font-weight-thin hidden-md-and-down"><v-icon>mdi-wrench</v-icon> Aktuell unfertig und in Bearbeitung</h1>
      </div>

      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-btn @click="showBar = !showBar" class="hide-bar-button"><v-icon v-if="showBar">mdi-arrow-up-bold</v-icon><v-icon v-else>mdi-arrow-down-bold</v-icon></v-btn>

    <v-main>
      <HelloWorld/>
    </v-main>
  </v-app>
</template>

<style>
  .hide-bar-button{
    position: fixed;
    top: 5px;
    right: 5px;
    z-index: 1000;
  }
</style>

<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',

  components: {
    HelloWorld,
  },

  data: () => ({
    showBar: true
  }),
};
</script>
